import { useEffect, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { SelectChangeEvent } from '@mui/material/Select';
import { useGetLatestDeals } from '@hooks/useDeals';
import trackUse from '@utils/trackUse';
import useLocalStorage from '@hooks/useLocalStorage';
import { useGetFavorites } from '@hooks/useFavorites';
import config from '@configFile';
import { logPostHogEvent, sortDeals } from '@utils/index';
import { useGetUserSettings } from '@hooks/useUserSettings';
import { useGetUserData } from '@hooks/useGetUserData';
import { DealPostType } from '@types';
import { dealsAction } from '../../actions';
import {
  filterLatestDeals,
  getNextDealASIN,
  getPreviousDealASIN
} from './utils';
import dealPageConfig from './defaultConfig';

export const useDealsState = (
  isEventDay: boolean | undefined,
  dealSummaryASIN: string | null
) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { data: user } = useGetUserData();
  const { data: userSettings } = useGetUserSettings();
  const hasStickyFiltersEnabled =
    user && userSettings?.databaseStickyFilters === true;

  const { data: dealsData, isLoading: latestDealsLoading } =
    useGetLatestDeals();
  const latestDeals = dealsData?.latestDeals;
  const tagData = dealsData?.tag || config.AFFILIATE_TAGS.DATABASE_CONTROL;
  const searchParams = new URLSearchParams(location?.search);
  const shouldShowV2Layout =
    searchParams?.get('layout') === 'v2' ||
    tagData === config.AFFILIATE_TAGS.DATABASE_TEST;
  const { data: favoriteItems = [] } = useGetFavorites();

  // State management for filters and settings
  const [primeDayLocal, setPrimeDayLocal] = useState(isEventDay || false);
  const [primeDaySticky, setPrimeDaySticky] = useLocalStorage(
    'dashboard_prime_day',
    isEventDay || dealPageConfig.defaultPrimeDay
  );
  const primeDay = hasStickyFiltersEnabled ? primeDaySticky : primeDayLocal;
  const setPrimeDay = hasStickyFiltersEnabled
    ? setPrimeDaySticky
    : setPrimeDayLocal;

  const [trendingLocal, setTrendingLocal] = useState(false);
  const [trendingSticky, setTrendingSticky] = useLocalStorage(
    'dashboard_trending',
    dealPageConfig.defaultTrending
  );
  const trending = hasStickyFiltersEnabled ? trendingSticky : trendingLocal;
  const setTrending = hasStickyFiltersEnabled
    ? setTrendingSticky
    : setTrendingLocal;

  const [strategyFilterLocal, setStrategyFilterLocal] = useState('');
  const [strategyFilterSticky, setStrategyFilterSticky] = useLocalStorage(
    'dashboard_strategyFilter',
    dealPageConfig.strategyFilter
  );
  const strategyFilter = hasStickyFiltersEnabled
    ? strategyFilterSticky
    : strategyFilterLocal;
  const setStrategyFilter = hasStickyFiltersEnabled
    ? setStrategyFilterSticky
    : setStrategyFilterLocal;

  const [sortLocal, setSortLocal] = useState(dealPageConfig.defaultSort);
  const [sortSticky, setSortSticky] = useLocalStorage(
    'dashboard_sort',
    dealPageConfig.defaultSort
  );
  const sort = hasStickyFiltersEnabled ? sortSticky : sortLocal;
  const setSort = hasStickyFiltersEnabled ? setSortSticky : setSortLocal;

  const [onlySponsored, setOnlySponsored] = useState(
    dealPageConfig.defaultOnlySponsored
  );

  const [onlyRatePromotion, setOnlyRatePromotion] = useState(
    dealPageConfig.defaultOnlyRatePromotion
  );

  const [underPrice, setUnderPrice] = useState(
    dealPageConfig.defaultUnderPrice
  );
  const [percentOff, setPercentOff] = useState(
    dealPageConfig.defaultPercentOff
  );
  const [overPrice, setOverPrice] = useState(dealPageConfig.defaultOverPrice);

  const [onlyFavoritesLocal, setOnlyFavoritesLocal] = useState(
    dealPageConfig.defaultOnlyFavorites
  );
  const [onlyFavoritesSticky, setOnlyFavoritesSticky] = useLocalStorage(
    'dashboard_filter_only_favorites',
    dealPageConfig.defaultOnlyFavorites
  );
  const onlyFavorites = hasStickyFiltersEnabled
    ? onlyFavoritesSticky
    : onlyFavoritesLocal;
  const setOnlyFavorites = hasStickyFiltersEnabled
    ? setOnlyFavoritesSticky
    : setOnlyFavoritesLocal;

  const [onlyShowNewLocal, setOnlyShowNewLocal] = useState(
    dealPageConfig.defaultOnlyShowNew
  );

  const [onlyShowPromotions, setOnlyShowPromotions] = useState(
    dealPageConfig.defaultOnlyShowPromotions
  );

  const [onlyShowNewSticky, setOnlyShowNewSticky] = useLocalStorage(
    'dashboard_filter_only_show_new',
    dealPageConfig.defaultOnlyShowNew
  );
  const onlyShowNew = hasStickyFiltersEnabled
    ? onlyShowNewSticky
    : onlyShowNewLocal;
  const setOnlyShowNew = hasStickyFiltersEnabled
    ? setOnlyShowNewSticky
    : setOnlyShowNewLocal;

  const [onlyCouponsLocal, setOnlyCouponsLocal] = useState(
    dealPageConfig.defaultOnlyCoupons
  );
  const [onlyCouponsSticky, setOnlyCouponsSticky] = useLocalStorage(
    'dashboard_filter_only_coupons',
    dealPageConfig.defaultOnlyCoupons
  );
  const onlyCoupons = hasStickyFiltersEnabled
    ? onlyCouponsSticky
    : onlyCouponsLocal;
  const setOnlyCoupons = hasStickyFiltersEnabled
    ? setOnlyCouponsSticky
    : setOnlyCouponsLocal;

  const [onlySubscribeSaveLocal, setOnlySubscribeSaveLocal] = useState(
    dealPageConfig.defaultOnlySubscribeSave
  );
  const [onlySubscribeSaveSticky, setOnlySubscribeSaveSticky] = useLocalStorage(
    'dashboard_filter_only_subscribe_save',
    dealPageConfig.defaultOnlySubscribeSave
  );
  const onlySubscribeSave = hasStickyFiltersEnabled
    ? onlySubscribeSaveSticky
    : onlySubscribeSaveLocal;
  const setOnlySubscribeSave = hasStickyFiltersEnabled
    ? setOnlySubscribeSaveSticky
    : setOnlySubscribeSaveLocal;

  const [categoryFiltersLocal, setCategoryFiltersLocal] = useState([]);
  const [categoryFiltersSticky, setCategoryFiltersSticky] = useLocalStorage(
    'dashboard_filter_category_filters_chips',
    []
  );
  const categoryFilters = hasStickyFiltersEnabled
    ? categoryFiltersSticky
    : categoryFiltersLocal;
  const setCategoryFilters = hasStickyFiltersEnabled
    ? setCategoryFiltersSticky
    : setCategoryFiltersLocal;

  const [brandFilters, setBrandFilters] = useState<string[]>([]);
  const [isFiltering, setIsFiltering] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [searchFilterDebounced, setSearchFilterDebouncedFunc] = useState('');

  const setSearchFilterDebounced = useCallback((value: string) => {
    setSearchFilterDebouncedFunc(value);
    if (value) {
      trackUse({ item: 'search-bar-deals-page', value, type: 'SEARCH' });
    }
  }, []);

  const [onlyShowAveragePriceLower, setOnlyShowAveragePriceLower] = useState(
    dealPageConfig.defaultOnlyShowAveragePriceLower
  );
  const [totalSoldLessThan, setTotalSoldLessThan] = useState<number | null>(
    dealPageConfig.defaultTotalSoldLessThan
  );
  const [totalSoldMoreThan, setTotalSoldMoreThan] = useState<number | null>(
    dealPageConfig.defaultTotalSoldMoreThan
  );
  const [onlyShowOlderThanDays, setOnlyShowOlderThanDays] = useState<
    number | null
  >(dealPageConfig.defaultOnlyShowOlderThanDays);
  const [amount, setAmount] = useState(dealPageConfig.initialNumDeals);

  let timeoutId: NodeJS.Timeout | null = null;

  const unfilteredDealsWithASIN = (latestDeals || []).filter((p) => !!p.ASIN);

  const dealsWithASIN = sortDeals(
    filterLatestDeals(
      {
        onlyFavorites,
        onlyShowNew,
        onlyCoupons,
        onlySubscribeSave,
        primeDay,
        categoryFilters,
        brandFilters,
        searchFilter: searchFilterDebounced,
        trending,
        onlySponsored,
        onlyRatePromotion,
        underPrice,
        overPrice,
        strategyFilter,
        percentOff,
        onlyShowAveragePriceLower,
        totalSoldLessThan,
        totalSoldMoreThan,
        onlyShowOlderThanDays,
        onlyShowPromotions
      },
      unfilteredDealsWithASIN,
      favoriteItems,
      searchFilterDebounced
    ),
    sort
  ) as DealPostType[];

  useEffect(() => {
    const navigateOnKey = (e: KeyboardEvent) => {
      let nextDealASIN = null;
      let trackUseName = 'next-deal-keyboard';
      if (e.key === 'ArrowRight') {
        nextDealASIN = getNextDealASIN(dealSummaryASIN, dealsWithASIN);
      } else if (e.key === 'ArrowLeft') {
        nextDealASIN = getPreviousDealASIN(dealSummaryASIN, dealsWithASIN);
        trackUseName = 'prev-deal-keyboard';
      }
      if (nextDealASIN) {
        trackUse({
          item: trackUseName,
          value: `/deal/${nextDealASIN}`,
          type: 'KEYBOARD'
        });
        logPostHogEvent(trackUseName, {
          value: `/deal/${nextDealASIN}`,
          type: 'KEYBOARD'
        });
        history.push(`/deal/${nextDealASIN}`);
        dispatch(dealsAction.getDealSummary(nextDealASIN));
      }
    };

    window.addEventListener('keydown', navigateOnKey);
    return () => window.removeEventListener('keydown', navigateOnKey);
  }, [dealSummaryASIN, dealsWithASIN]);

  const toggleOnlyCoupons = () => setOnlyCoupons(!onlyCoupons);

  const resetAll = () => {
    setTrending(dealPageConfig.defaultTrending);
    setOnlyCoupons(dealPageConfig.defaultOnlyCoupons);
    setOnlySubscribeSave(dealPageConfig.defaultOnlySubscribeSave);
    setOnlyShowNew(dealPageConfig.defaultOnlyShowNew);
    setCategoryFilters([]);
    setOnlyFavorites(dealPageConfig.defaultOnlyFavorites);
    setSearchFilter('');
    setIsFiltering(false);
    setSearchFilterDebounced('');
    setPrimeDay(dealPageConfig.defaultPrimeDay);
    setStrategyFilter(dealPageConfig.strategyFilter);
    setUnderPrice(dealPageConfig.defaultUnderPrice);
    setOverPrice(dealPageConfig.defaultOverPrice);
    setPercentOff(dealPageConfig.defaultPercentOff);
    setBrandFilters(dealPageConfig.defaultBrandFilters);
    // reset the admin filters too
    setOnlySponsored(dealPageConfig.defaultOnlySponsored);
    setOnlyRatePromotion(dealPageConfig.defaultOnlyRatePromotion);
    setOnlyShowAveragePriceLower(
      dealPageConfig.defaultOnlyShowAveragePriceLower
    );
    setTotalSoldLessThan(dealPageConfig.defaultTotalSoldLessThan);
    setTotalSoldMoreThan(dealPageConfig.defaultTotalSoldMoreThan);
    setOnlyShowPromotions(dealPageConfig.defaultOnlyShowPromotions);
    window?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const loadMore = () => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(
      () => setAmount(amount + dealPageConfig.loadPerScroll),
      dealPageConfig.fakeLoadTimeout
    );
  };

  const handleSortChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    setSort(value);
    trackUse({ item: 'sort-deal-database-change', value, type: 'ACTION' });
    logPostHogEvent('sort-deal-database-change', { value, type: 'ACTION' });
  };

  const debounceUpdateFilteredItems = useCallback(
    debounce((value) => setSearchFilterDebounced(value), 500),
    []
  );

  useEffect(() => {
    debounceUpdateFilteredItems(searchFilter);
    return () => debounceUpdateFilteredItems.cancel();
  }, [searchFilter, debounceUpdateFilteredItems]);

  return {
    primeDay,
    setPrimeDay,
    trending,
    setTrending,
    sort,
    setSort,
    onlyFavorites,
    setOnlyFavorites,
    onlyShowNew,
    setOnlyShowNew,
    onlyCoupons,
    setOnlyCoupons,
    onlySubscribeSave,
    setOnlySubscribeSave,
    categoryFilters,
    setCategoryFilters,
    brandFilters,
    setBrandFilters,
    isFiltering,
    setIsFiltering,
    searchFilter,
    setSearchFilter,
    searchFilterDebounced,
    setSearchFilterDebounced,
    strategyFilter,
    setStrategyFilter,
    amount,
    setAmount,
    dealsWithASIN,
    latestDealsLoading,
    shouldShowV2Layout,
    tagData,
    unfilteredDealsWithASIN,
    toggleOnlyCoupons,
    resetAll,
    loadMore,
    handleSortChange,
    onlySponsored,
    setOnlySponsored,
    onlyRatePromotion,
    setOnlyRatePromotion,
    underPrice,
    setUnderPrice,
    overPrice,
    setOverPrice,
    percentOff,
    setPercentOff,
    onlyShowAveragePriceLower,
    setOnlyShowAveragePriceLower,
    totalSoldLessThan,
    setTotalSoldLessThan,
    totalSoldMoreThan,
    setTotalSoldMoreThan,
    onlyShowOlderThanDays,
    setOnlyShowOlderThanDays,
    onlyShowPromotions,
    setOnlyShowPromotions,
    hasFilters: () =>
      categoryFilters?.length !== 0 ||
      searchFilterDebounced !== '' ||
      strategyFilter !== '' ||
      onlyFavorites ||
      onlyShowNew ||
      onlyCoupons ||
      trending ||
      primeDay ||
      onlySubscribeSave ||
      underPrice ||
      overPrice ||
      percentOff ||
      onlyShowAveragePriceLower ||
      totalSoldLessThan ||
      totalSoldMoreThan ||
      onlyShowOlderThanDays ||
      brandFilters.length > 0 ||
      onlyRatePromotion ||
      onlySponsored ||
      onlyShowPromotions
  };
};
