import React, { memo, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  ButtonBase,
  CardActions,
  CardContent,
  Box,
  Typography,
  Button,
  Tooltip,
  useMediaQuery
} from '@mui/material';
import { formatDistance } from 'date-fns';
import { useTheme } from '@mui/system';
import colors from '@utils/colors';
import { CarouselRibbon } from '@components/Ribbon';
import config from '@configFile';
import { DEALS_UPDATE_RECEIPT_DEAL } from '@types';
import CouponChip from '@components/CouponChip';
import FavoriteButton from '@components/FavoriteButton';
import NewTooltip from '@components/NewTooltip';
import { MonetizingLink } from '@components/index';
import {
  parseTitle,
  // getPostPrice,
  getProductLink,
  logPostHogEvent,
  getCurrentDealEventStrObj
} from '@utils/index';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getPostPriceData } from '@utils/formatUtils';
import SubscribeSaveChip from '@components/SubscribeSaveChip';
import PromoFixedChip from '@components/PromoFixedChip';
import PromotionChip from '@components/PromotionChip';
import trackUse from '@utils/trackUse';
import { useGetFavorites } from '@hooks/useFavorites';
import { useGetUserSettings, defaultUISettings } from '@hooks/useUserSettings';
import { useGetUserData } from '@hooks/useGetUserData';
import { dealsAction } from '@actions/index';
import StrategyIds from '@pages/Deals/components/DealCard/components/StrategyIds';
import classes from '@pages/Deals/components/styles';
import DealCardActions from '@pages/Deals/components/DealCard/components/DealCardActions';
import PercentageDiscount from '@pages/Deals/components/DealCard/components/PercentageDiscount';
import { DealCardProps } from '@pages/Deals/components/DealCard';

const PriceDisplay = memo((props: DealCardProps) => {
  const data = getPostPriceData(props, false);
  const {
    formattedFinalPrice,
    leadPart,
    qtyStr,
    listPriceBlock,
    extra,
    lastPart
  } = data;
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  // if (!isAdmin) {
  //   return (
  //     <Typography
  //       gutterBottom
  //       variant="h5"
  //       component="div"
  //       sx={{
  //         display: '-webkit-box !important',
  //         WebkitBoxOrient: 'vertical',
  //         overflow: 'hidden',
  //         textOverflow: 'ellipsis',
  //         fontSize: '14px !important',
  //         color: isDarkMode ? '#eaeaea' : theme.palette.secondaryTextColor,
  //         fontWeight: '600 !important',

  //         '@media (max-width: 48rem)': {
  //           WebkitLineClamp: 'unset !important'
  //         }
  //       }}
  //     >
  //       {`${getPostPrice(props, false)}`}
  //     </Typography>
  //   );
  // }

  return (
    <Box mb="4px" display="flex" alignItems="center" flexWrap="wrap">
      {leadPart ? (
        <Box
          component="span"
          sx={{
            marginRight: '4px',
            fontWeight: 'bold'
          }}
        >
          {leadPart.trim()}
        </Box>
      ) : null}
      <Typography
        component="span"
        variant="h6"
        sx={{
          fontWeight: 'bold',
          marginRight: '4px',
          color: isDarkMode ? '#FFFFFF' : '#b30000'
        }}
      >
        {formattedFinalPrice}
      </Typography>
      {qtyStr ? (
        <Box
          component="span"
          sx={{
            marginRight: '4px',
            fontWeight: 'bold'
          }}
        >
          {qtyStr.trim()}
        </Box>
      ) : null}
      {listPriceBlock ? (
        <Box
          component="span"
          sx={{
            fontWeight: 'bold'
          }}
        >
          {listPriceBlock}
        </Box>
      ) : null}
      {extra ? (
        <Box
          component="span"
          sx={{
            fontWeight: 'bold',
            fontStyle: 'italic'
          }}
        >
          {extra}
        </Box>
      ) : null}
      {lastPart ? (
        <Box
          component="span"
          sx={{
            fontWeight: 'bold',
            fontStyle: 'italic'
          }}
        >
          {lastPart}
        </Box>
      ) : null}
    </Box>
  );
});

const DealInnerCard = memo((props: DealCardProps) => {
  const {
    title,
    image,
    image240Url,
    postDate,
    ASIN,
    finalPrice,
    ss,
    couponPercentage,
    couponFixed,
    promoFixed,
    extraDeal,
    tag,
    socialBought,
    index
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const { data: userFavorites } = useGetFavorites();
  const { data: userSettings } = useGetUserSettings();
  const { data: userData } = useGetUserData();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const imageLoading =
    (isMobile && index > 3) || (!isMobile && index > 11) ? 'lazy' : 'eager';
  const affiliateTag = tag || config.AFFILIATE_TAGS.DATABASE_CONTROL;

  const shouldShowRibbons = useMemo(() => {
    return !!userData && userSettings
      ? !!userSettings.databaseShowRibbons
      : defaultUISettings.databaseShowRibbons;
  }, [userSettings]);

  const isFavorite = useMemo(() => {
    return ASIN && userFavorites?.includes(ASIN);
  }, [ASIN, userFavorites]);

  const link = useMemo(
    () => getProductLink(ASIN, affiliateTag),
    [ASIN, affiliateTag]
  );

  const handleDetailsClick = useCallback(() => {
    dispatch({ type: DEALS_UPDATE_RECEIPT_DEAL, receiptDeal: props });
    dispatch(dealsAction.getDealSummary(ASIN));
    history.push(`/deal/${ASIN}`);
    trackUse({ item: 'details-button-click', value: ASIN, type: 'CLICK' });
    logPostHogEvent('details-button-click', {
      item: 'details-button-click',
      value: ASIN,
      type: 'CLICK'
    });
  }, [dispatch, history, ASIN, props]);

  const getCardChips = useMemo(() => {
    const chips = [];

    if (couponPercentage || couponFixed) {
      chips.push(<CouponChip {...props} key="coupon-chip" />);
    }

    if (ss) {
      chips.push(<SubscribeSaveChip {...props} key="ss-chip" />);
    }

    if (promoFixed) {
      chips.push(<PromoFixedChip {...props} key="promo-chip" />);
    }

    if (extraDeal) {
      chips.push(<PromotionChip {...props} key="promotion-chip" />);
    }

    return chips.length > 0 ? (
      <Box sx={classes.chipWrapper}>
        <PercentageDiscount {...props} />
        {chips}
      </Box>
    ) : (
      <PercentageDiscount {...props} />
    );
  }, [
    couponPercentage,
    couponFixed,
    extraDeal,
    ss,
    promoFixed,
    classes.chipWrapper,
    props
  ]);

  const renderNewTooltip = useMemo(() => {
    return (
      <NewTooltip variant="caption" usePostDate deal={props} label="*New*" />
    );
  }, [props]);

  const shouldDisplayEventDeal = useMemo(() => {
    const currentEventObj = getCurrentDealEventStrObj();
    return (
      currentEventObj &&
      currentEventObj?.shouldShowDeals &&
      (props.listingMode || '')
        .toLowerCase()
        .includes(currentEventObj.value.toLowerCase())
    );
  }, [props.listingMode]);

  const image240UrlWebp = image240Url?.replace('.jpg', '.webp');

  return (
    <>
      <Box>
        <MonetizingLink
          href={link}
          clickType="TEXT"
          tag={affiliateTag}
          item="deal-image-wrapper"
        >
          {shouldShowRibbons &&
            (props.isBestSeller || shouldDisplayEventDeal || isFavorite) && (
              <CarouselRibbon
                dealSummary={props}
                onlyShowImportant
                opacity={1}
                padding="1px 8px"
                fontSize="11px"
              />
            )}
          <ButtonBase sx={classes.imageWrapper}>
            <Box
              sx={{
                // width: 140,
                height: 140,
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '3px',
                paddingLeft: '4px'
              }}
            >
              <Box
                component="img"
                alt={parseTitle(title)}
                src={(image240UrlWebp || image)?.replace('http:', 'https:')}
                sx={classes.imageMobile}
                loading={imageLoading}
                role="presentation"
              />
            </Box>
          </ButtonBase>
        </MonetizingLink>
        <CardContent sx={classes.cardContent}>
          <MonetizingLink
            href={link}
            clickType="TEXT"
            tag={affiliateTag}
            item="deal-card-content"
            sx={{
              textDecoration: 'none',
              color: isDarkMode ? '#ccc' : colors.jdbPurple,

              '&:hover': {
                color: isDarkMode ? '#fff' : 'black'
              }
            }}
          >
            <Tooltip
              enterDelay={1000}
              title={parseTitle(title)}
              placement="top"
            >
              <Typography
                variant="h5"
                component="div"
                sx={{
                  display: '-webkit-box !important',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: '14px !important',
                  '@media (min-width: 1700px)': {
                    // larger text for larger screens
                    fontSize: '14px !important'
                  },
                  color: isDarkMode ? '#FFFFFF' : theme.palette.primaryTextColor
                }}
              >
                {`${parseTitle(title)}`}
              </Typography>
            </Tooltip>
            <PriceDisplay {...props} />
            <Typography
              sx={{
                ...classes.mobileDate,
                color: theme.palette.secondaryTextColor
              }}
            >
              {renderNewTooltip}
              {`Added ${formatDistance(new Date(postDate), new Date(), {
                addSuffix: true
              })}`}
            </Typography>
            {socialBought ? (
              <Tooltip
                enterDelay={1000}
                title="To give you a sense of the popularity of the item we provide how many Amazon says were purchased in the past month."
                placement="top"
              >
                <Typography
                  sx={{
                    ...classes.mobileDate,
                    color: theme.palette.secondaryTextColor
                  }}
                >
                  {socialBought.replace('bought in', 'bought on Amazon in')}
                </Typography>
              </Tooltip>
            ) : null}
          </MonetizingLink>
          <Box>{getCardChips}</Box>
        </CardContent>
      </Box>
      <StrategyIds {...props} />
      <CardActions sx={classes.actionButtons}>
        <Button
          key="btn-summary"
          sx={{
            backgroundColor: 'transparent !important',
            ...(isDarkMode
              ? {
                  borderColor: '#d1d1d1 !important'
                }
              : {})
          }}
          size="small"
          variant="outlined"
          onClick={handleDetailsClick}
        >
          Details
        </Button>
        <FavoriteButton ASIN={ASIN} finalPrice={finalPrice} />
        <Box display="inline-block">
          <DealCardActions {...props} />
        </Box>
      </CardActions>
    </>
  );
});

export default DealInnerCard;
