/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import {
  Box,
  Typography,
  Link,
  useMediaQuery,
  Divider,
  Skeleton
} from '@mui/material';
import { useTheme } from '@mui/system';
import config from '@configFile';
import { useSignUpModal } from '@hooks/useLoginModal';
import { getCurrentDealEventStrObj } from '@utils/index';

import { useGetUserData } from '@hooks/useGetUserData';
import { DealPostType, FilterChipConfigType } from '@types';
import BrandFilter from '@pages/Deals/components/BrandFilter';
import MainFilterChip from '../Filters/components/MainFilter';
import { FilterFunctionsType } from '../Filters/utils';
import CategoryChip from '../Filters/components/CategoryFilter';
import PriceFilter from '../Filters/components/PriceFilter';
import PercentageFilter from '../Filters/components/PercentageFilter';
import AdminChip from '../Filters/components/AdminChip';

const FilterDrawer = ({
  funcs: {
    trending,
    setTrending,
    primeDay,
    setPrimeDay,
    onlyCoupons,
    setOnlyCoupons,
    onlySubscribeSave,
    setOnlySubscribeSave,
    onlyShowPromotions,
    setOnlyShowPromotions,
    onlyShowNew,
    setOnlyShowNew,
    setCategoryFilters,
    categoryFilters,
    brandFilters,
    setBrandFilters,
    onlyFavorites,
    setOnlyFavorites,
    onlySponsored,
    setOnlySponsored,
    underPrice,
    setUnderPrice,
    overPrice,
    setOverPrice,
    percentOff,
    setPercentOff,
    onlyShowAveragePriceLower,
    setOnlyShowAveragePriceLower,
    totalSoldLessThan,
    setTotalSoldLessThan,
    totalSoldMoreThan,
    setTotalSoldMoreThan,
    onlyShowOlderThanDays,
    setOnlyShowOlderThanDays,
    onlyRatePromotion,
    setOnlyRatePromotion,
    resetAll,
    hasFilters
  },
  currentlyShowingDeals,
  latestDealsLoading,
  showingStr
}: {
  funcs: FilterFunctionsType;
  currentlyShowingDeals: DealPostType[];
  latestDealsLoading: boolean;
  showingStr: string | null;
}) => {
  const { data: user, isLoading } = useGetUserData();
  const { showNonLoggedInModal } = useSignUpModal();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  // trigger a render after everything has finished loading
  // this is to attempt to fix the chip background bug
  useEffect(() => {
    if (!isLoading) {
      setOnlyFavorites(!!onlyFavorites);
    }
  }, [isLoading]);

  let filterChipConfig: FilterChipConfigType = [];

  const eventObj = getCurrentDealEventStrObj();

  if (eventObj && eventObj?.shouldShowDeals) {
    const {
      label,
      overrideChipLabel,
      overrideChipColor,
      overrideChipSelectedColor,
      overrideChipColorDarkMode,
      overrideChipSelectedColorDarkMode
    } = eventObj;

    const chipColor =
      (isDarkMode ? overrideChipColorDarkMode : overrideChipColor) ||
      eventObj.dealHighlightColor;
    const chipSelectedColor =
      (isDarkMode
        ? overrideChipSelectedColorDarkMode
        : overrideChipSelectedColor) ||
      eventObj.chipSelectedColor ||
      '#f50095';

    filterChipConfig.push({
      index: 0,
      label: overrideChipLabel || label,
      value: 'eventDay',
      icon: eventObj?.iconName || 'CelebrationIcon',
      iconColor: chipColor,
      // iconColor: !isEarlySale
      //   ? eventObj.dealHighlightColor
      //   : colors.earlyEventDeal,
      // selectedColor: !isEarlySale ? eventObj.chipSelectedColor : '#f50095',
      selectedColor: chipSelectedColor,

      selected: primeDay,
      onClick: () => setPrimeDay(!primeDay),
      isMultifilter: false
    });
  }

  filterChipConfig = filterChipConfig.concat([
    {
      index: 0,
      label: 'Popular',
      value: 'popular',
      icon: 'InsightsIcon',
      iconColor: '#C25700',
      selectedColor: '#EEA264',
      selected: !!trending,
      onClick: () => setTrending(!trending),
      isMultifilter: false
    },
    {
      index: 1,
      label: 'Coupon',
      value: 'coupon',
      icon: 'LocalOfferIcon',
      selectedColor: '#67a9f4',
      iconColor: theme.palette.couponColor,
      selected: !!onlyCoupons,
      onClick: () => setOnlyCoupons(!onlyCoupons),
      isMultifilter: false
    },
    {
      index: 2,
      label: 'Subscribe & Save',
      value: 'subscribeSave',
      icon: 'AutorenewIcon',
      selectedColor: '#4ddfce',
      iconColor: '#00796b',
      selected: onlySubscribeSave,
      onClick: () => setOnlySubscribeSave(!onlySubscribeSave),
      isMultifilter: false
    }
  ]);

  if (user) {
    filterChipConfig.push({
      index: 3,
      label: 'Favorites',
      value: 'favorites',
      icon: 'HeartIcon',
      iconColor: theme.palette.favoriteColor,
      selected: onlyFavorites,
      onClick: () => setOnlyFavorites(!onlyFavorites),
      isMultifilter: false
    });
  } else {
    filterChipConfig.push({
      index: 3,
      label: 'Favorites',
      value: 'favorites',
      icon: 'HeartIcon',
      iconColor: theme.palette.favoriteColor,
      selected: false,
      onClick: () => {
        showNonLoggedInModal({
          onLoginCBFn: () => {
            setOnlyFavorites(!onlyFavorites);
          },
          modalMessage: 'Please login for us to locate your favorites',
          featureName: 'chip-see-favorites-login-modal'
        });
      },
      isMultifilter: false
    });
  }

  filterChipConfig.push({
    index: 4,
    label: 'Promotion',
    value: 'promotion',
    icon: 'PaymentsIcon',
    iconColor: isDarkMode ? '#c488f7' : '#6900c2',
    selected: onlyShowPromotions,
    selectedColor: '#c488f7',
    onClick: () => setOnlyShowPromotions(!onlyShowPromotions),
    isMultifilter: false
  });

  filterChipConfig.push({
    index: 3,
    label: 'New',
    value: 'new',
    icon: 'NewReleasesIcon',
    iconColor: theme.palette.newColor,
    selected: onlyShowNew,
    selectedColor: '#fd5c5c',
    onClick: () => setOnlyShowNew(!onlyShowNew),
    isMultifilter: false
  });

  const categoryOptions = config.CATEGORY_FILTER.filter(
    (cat) => !cat?.skipDatabase
  ).map((category, index) => ({
    index: index + filterChipConfig.length,
    label: category.shortName,
    value: category.value,
    icon: category.icon,
    iconColor: 'inherit',
    isMultifilter: true,
    selected: categoryFilters.includes(category.value),
    onClick: () => {
      const newCategoryFilters = [...categoryFilters];
      if (newCategoryFilters.includes(category.value)) {
        newCategoryFilters.splice(
          newCategoryFilters.indexOf(category.value),
          1
        );
      } else {
        newCategoryFilters.push(category.value);
      }
      setCategoryFilters(newCategoryFilters);
    }
  }));

  const renderShowing = () => {
    if (!showingStr) {
      return (
        <Box>
          <Skeleton height="16px" width="142px" />
        </Box>
      );
    }

    return (
      <Box>
        <Typography variant="caption">{showingStr}</Typography>
      </Box>
    );
  };

  const selectedCountMainFilterCount = React.useMemo(() => {
    let filterCount = 0;
    if (underPrice || overPrice) {
      filterCount += 1;
    }

    if (percentOff) {
      filterCount += 1;
    }

    return (
      filterChipConfig.filter((filter) => filter.selected).length + filterCount
    );
  }, [filterChipConfig, underPrice, overPrice, percentOff]);
  const selectedCategoryCount = categoryOptions.filter(
    (filter) => filter.selected
  ).length;
  const selectedCount = selectedCountMainFilterCount + selectedCategoryCount;
  const selectedString = selectedCount > 0 ? ` (${selectedCount})` : '';
  const hasFiltersActive = selectedCount > 0 || hasFilters();

  const chipWrapperProps = {
    display: 'inline-block',
    mb: 1,
    mr: 0.5
  };

  const renderAdminFilterChip = () => {
    return (
      <AdminChip
        onlyShowAveragePriceLower={onlyShowAveragePriceLower}
        setOnlyShowAveragePriceLower={setOnlyShowAveragePriceLower}
        onlySponsored={onlySponsored}
        setOnlySponsored={setOnlySponsored}
        totalSoldLessThan={totalSoldLessThan}
        setTotalSoldLessThan={setTotalSoldLessThan}
        totalSoldMoreThan={totalSoldMoreThan}
        setTotalSoldMoreThan={setTotalSoldMoreThan}
        onlyShowOlderThanDays={onlyShowOlderThanDays}
        setOnlyShowOlderThanDays={setOnlyShowOlderThanDays}
        onlyRatePromotion={onlyRatePromotion}
        setOnlyRatePromotion={setOnlyRatePromotion}
      />
    );
  };

  return (
    <>
      <Box
        sx={{
          padding: isMobile ? '12px' : '0 4px',
          maxWidth: isMobile ? '100%' : 'initial'
        }}
      >
        <Box
          display="flex"
          justifyContent={isMobile ? 'start' : 'space-between'}
        >
          <Typography variant="overline">
            {`Filters ${selectedString}`}
          </Typography>
          <Box>
            <Box>
              <Link
                variant="overline"
                onClick={() => {
                  resetAll();
                }}
                sx={{
                  cursor: 'pointer',
                  color: isDarkMode ? theme.palette.linkColor : '#392e50',
                  fontWeight: 700,
                  fontSize: '0.75rem',
                  display: hasFiltersActive ? 'block' : 'none',
                  marginRight: '4px',
                  marginLeft: '12px'
                }}
                href="#"
              >
                Clear all
              </Link>
            </Box>
          </Box>
        </Box>
        {renderShowing()}
        <Divider
          variant="fullWidth"
          sx={{
            mt: 1,
            mb: 1
          }}
        />
        <Box display="flex">
          <Typography variant="overline">Quick Filters</Typography>
        </Box>
        <Box>
          {(filterChipConfig || []).map((filter) => {
            return (
              <Box {...chipWrapperProps} key={filter.label}>
                <MainFilterChip filter={filter} key={filter.label} />
              </Box>
            );
          })}
          <Box>
            <PriceFilter
              underPrice={underPrice}
              setUnderPrice={setUnderPrice}
              overPrice={overPrice}
              setOverPrice={setOverPrice}
            />
          </Box>
          <Box>
            <CategoryChip categoryOptions={categoryOptions} />
          </Box>

          <Box>
            <PercentageFilter
              percentOff={percentOff}
              setPercentOff={setPercentOff}
            />
          </Box>
          <Box>
            <BrandFilter
              brandFilters={brandFilters}
              setBrandFilters={setBrandFilters}
              currentlyShowingDeals={currentlyShowingDeals}
              latestDealsLoading={latestDealsLoading}
            />
          </Box>
          <Box mt={2}>{renderAdminFilterChip()}</Box>
        </Box>
      </Box>
    </>
  );
};
export default FilterDrawer;
